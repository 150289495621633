<template>
  <v-card flat class="hours">
    <v-row no-gutters v-if="hoursInfoLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="!hoursInfoLoading && hoursChartOptions" class="pa-0 mt-2"  :class="$vuetify.theme.dark ? 'dark-theme-chart' : ''">
      <v-col cols="12" lg="12" v-if="hasTimerPermission">
        <highcharts :options="hoursChartOptions"></highcharts>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['hasTimerPermission', 'hoursChartOptions', 'hoursInfoLoading']
}
</script>
